import Service from '@/js/service.js';
import { tokenRefresher, TokenLifetimeKeeper } from "@/auth-tools"

const service = new Service(`${process.env.VUE_APP_AUTH_SERVICE}/auth-service`);

const authService = {
    switchContext(projectId, organizationId, then, error) {
        service.postRequest(1,
            'context-switch',
            {
                project: parseInt(projectId),
                organisation: parseInt(organizationId),
            }, (tokens) => {
                TokenLifetimeKeeper.removeLifetime()
                tokenRefresher.login(tokens);

                then(...arguments);
            }, error,
        );
    },
};

export default authService;

