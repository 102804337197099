var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:('height:' + _vm.window.height + 'px')},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[(typeof _vm.result['infoFormMessage'] != 'undefined')?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"max-width":"max-content"}},[_c('messagesContainer',{attrs:{"result":_vm.result}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mr-9",staticStyle:{"max-width":"600px"}},[_c('div',{staticStyle:{"position":"relative"},style:({
            bottom: _vm.$vuetify.breakpoint.xl ? '108px' : '10px',
            'font-size': _vm.$vuetify.breakpoint.xs ? '1.5em' : '1.8em',
          })},[_vm._v(" Login to Acctopus Degust ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Username (Email)","rules":_vm.getRules(_vm.result.fields.c_email.fieldAttrInput, this.result, 'c_email', this),"name":"c_email","id":"loginc_email","placeholder":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":_vm.getRules(
                    _vm.result.fields.c_password.fieldAttrInput,
                    this.result,
                    'c_password',
                    this
                  ),"type":_vm.show1 ? 'text' : 'password',"name":"c_password","label":"Password","id":"loginc_password"},on:{"click:append":function($event){_vm.show1 = !_vm.show1},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitForm('requestlogin')}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"align":"start","justify":"center"}},[_c('primaryButton',{attrs:{"label":"Login","type":"button","color":"save","id":"login_requestlogin","data-type":"loginButton","disabled":!_vm.isValid || _vm.loading,"loading":_vm.loading && _vm.isValid,"icon":"mdi-login","shrink":""},on:{"click":function($event){return _vm.submitForm('requestlogin')}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('a',{staticClass:"ml-2",attrs:{"href":"?f=global&f2=register"}},[_vm._v(" "+_vm._s(_vm.language.componentLabel.labelRU)+" ")]),_c('span',[_vm._v("|")]),_c('router-link',{attrs:{"id":"resetPasswordLink","to":"?f=global&f2=resetPasswordRequest"}},[_vm._v(" "+_vm._s(_vm.language.componentLabel.labelFP)+" ")])],1)],1)],1)],1),_c('v-col',{staticClass:"mb-12",staticStyle:{"max-width":"675px"},attrs:{"cols":"12","sm":"5"}},[_c('div',[_c('div',{style:('height:' + _vm.spaceHeight)}),_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Welcome back to Acctopus Degust®, the cloud-based telco test environment where enterprises, teams, and individuals test mobile and fixed networks' quality, protocols, usage, and compliance ")]),_c('p',{staticStyle:{"font-weight":"bold"},style:({
              display: _vm.$vuetify.breakpoint.xsOnly ? 'inline-grid' : '',
            })},[_c('span',{staticStyle:{"padding-right":"30px"}},[_vm._v("...to whatever extend")]),_c('span',{staticStyle:{"padding-right":"30px"}},[_vm._v("...at any scale")]),_c('span',{staticClass:"text-no-wrap"},[_vm._v("...in any size of teams")])]),_c('br'),_c('p',[_vm._v(" After logging in, you can try out our brand new 5G SBI test features and automated packet trace evaluation. ")]),_c('p',[_vm._v(" Or simply turn Raspberry Pi computers into automated monitoring probes using our free-of-charge OS image. You will be able to perform extensive UDP and TCP tests on throughput, latency, jitter, RTT, or drops using well-known tools like iPerf or tools recommended by the Broadband Forum, the IETF, or other standardization bodies. Degust also supports publicly available speed test services as well as most of your existing scripts. ")]),_c('p',[_vm._v(" By running the Degust Explorer image on hundreds or even thousands of devices, you can examine and report on the quality of your network in a very short time. ")]),_c('p',[_vm._v(" If you are new to Degust®, please "),_c('router-link',{attrs:{"to":"?f=global&f2=register"}},[_vm._v("register")]),_vm._v(" for free, get your OS image and monitor the world. ")],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }